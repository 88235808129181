/** @format */

import Vue from 'vue'
import VueCompositionApi, { ref } from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive, toRefs } from '@vue/composition-api'
// import Store from '@/store'

// ? mini store
const selectedInvoiceDate = ref(new Date())
const selectedInvoiceDueDate = ref(new Date())
const selectedInvoiceLanguage = ref('se')
// ? form the selected dispatch variables
const invoiceExchangeRate = ref('')
const invoiceExchangeUnit = ref('')
const invoiceAddressId = ref('')
const invoiceType = ref('')

const showGetSelectedDispatchesData = ref(false)
const variablesQ2 = ref({})

const stateSalesInvoice = reactive({
	ourCompany: 'x',
	customer: {},
	customer_id: '',
	isInvoiceSendByEmail: false,
	invoiceAddress: {},
	dispatches: [],
	selectedDispatches: [],
	dispatchTableColumns: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Dispatch Number',
			field: 'dispatch_number',
			width: '110px',
		},
		{
			label: 'Dispatch Date',
			field: 'dispatch_date',
			width: '135px',
		},
		{
			label: 'Customer',
			field: 'customer',
		},
		{
			label: 'Customer Terms',
			field: 'terms',
			width: '185px',
		},
	],
	selectDispatchToInvoiceColumns: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Dispatch Number',
			field: 'dispatch_number',
			width: '110px',
		},
		{
			label: 'Dispatch Date',
			field: 'dispatch_date',
			width: '135px',
		},
		{
			label: 'Dispatch Type',
			field: 'dispatch_type',
			width: '115px',
		},
		{
			label: 'Invoice Address Nickname',
			field: 'dispatch_headers_addresses_rel_dispatch_address.address_nickname',
		},
		{
			label: 'Dispatch Total',
			field: 'dispatch_total',
			html: true,
			type: 'number',
			width: '135px',
		},
		{
			label: 'Dispatch Exchange',
			field: 'dispatch_exchange_unit',
			width: '115px',
		},
		{
			label: 'Preview',
			field: 'preview',
			width: '50px',
			sortable: false,
		},
	],
	selectedDispatchesColumns: [
		{
			label: 'Remove',
			field: 'remove',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Dispatch Number',
			field: 'dispatch_number',
			width: '110px',
		},
		{
			label: 'Dispatch Date',
			field: 'dispatch_date',
			width: '135px',
		},
		{
			label: 'Dispatch Type',
			field: 'dispatch_type',
			width: '115px',
		},
		{
			label: 'Invoice Address Nickname',
			field: 'dispatch_headers_addresses_rel_dispatch_address.address_nickname',
		},
		{
			label: 'Dispatch Total',
			field: 'dispatch_total',
			html: true,
			type: 'number',
			width: '135px',
		},
		{
			label: 'Dispatch Exchange',
			field: 'dispatch_exchange_unit',
			width: '115px',
		},
		{
			label: 'Preview',
			field: 'preview',
			width: '50px',
			sortable: false,
		},
	],
})
export default function useSalesInvoiceState() {
	return {
		selectedInvoiceDate,
		selectedInvoiceDueDate,
		selectedInvoiceLanguage,
		invoiceExchangeRate,
		invoiceExchangeUnit,
		invoiceAddressId,
		invoiceType,
		showGetSelectedDispatchesData,
		variablesQ2,
		...toRefs(stateSalesInvoice),
		stateSalesInvoice,
	}
}
